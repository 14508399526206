/* Base styles for Tiptap content */
.ProseMirror {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

/* Paragraphs */
.ProseMirror p {
  margin: 0; /* Remove all margin from paragraphs */
  padding: 0; /* Ensure no padding is added */
}

/* Headings */
.ProseMirror h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0; /* Remove top and bottom margin */
  padding: 0.5em 0; /* Optional: add controlled padding */
}
.ProseMirror h2 {
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
  padding: 0.4em 0;
}
.ProseMirror h3 {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  padding: 0.3em 0;
}

/* Lists */
.ProseMirror ul,
.ProseMirror ol {
  padding-left: 20px;
  margin: 0; /* Remove margin for lists */
}
.ProseMirror li {
  margin: 0; /* Remove margin between list items */
  padding: 0.2em 0; /* Optional: control spacing inside list items */
}

/* Links */
.ProseMirror a {
  color: #1a73e8;
  text-decoration: underline;
}

/* Blockquotes */
.ProseMirror blockquote {
  padding-left: 16px;
  border-left: 4px solid #ccc;
  color: #555;
  font-style: italic;
  margin: 0; /* Remove margins for blockquotes */
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/* Code */
.ProseMirror code {
  background: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}

/* Scrollbar for overflow */
.ProseMirror {
  max-height: auto;
  overflow-y: auto;
}

/* Placeholder */
.tiptap-placeholder {
  color: #718096;
  font-style: italic;
}
.tiptap-placeholder::before {
  content: attr(data-placeholder);
  color: #718096;
  font-style: italic;
}

/* Custom link style */
.custom-link {
  color: blue;
  text-decoration: underline;
}
